import React, { useEffect, useState, useCallback, memo } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import PropTypes from 'prop-types';
import { accessToken } from '../../utils/constants';
import TextBlockTwo from './TextBlockTwo';

const MapTwo = ({ mapDataArray }) => {
  const mapBoxStyle = 'outdoors-v11';
  const [mapData, setMapData] = useState([]);
  const [chapters, setChapters] = useState([]);

  const initializeMapData = useCallback(() => {
    setMapData(mapDataArray);

    const userChapters = mapDataArray.map(
      ({ pitch, longitude, latitude, zoom }) => ({
        pitch,
        duration: 6000,
        center: [longitude, latitude],
        zoom,
      })
    );

    setChapters(userChapters);
  }, [mapDataArray]);

  const initializeMap = useCallback(() => {
    mapboxgl.accessToken = accessToken;

    const map = new mapboxgl.Map({
      container: 'map',
      style: `mapbox://styles/mapbox/${mapBoxStyle}`,
      center: chapters[0]?.center || [0, 0],
      zoom: chapters[0]?.zoom || 45,
      pitch: chapters[0]?.pitch || 45,
    });

    let activeChapterName = '0';

    const setActiveChapter = (chapterName) => {
      if (chapterName === activeChapterName) return;

      map.flyTo(chapters[chapterName]);

      document.getElementById(chapterName).classList.add('active');
      document.getElementById(activeChapterName).classList.remove('active');

      activeChapterName = chapterName;
    };

    const isElementOnScreen = (id) => {
      const element = document.getElementById(id);
      if (element) {
        const bounds = element.getBoundingClientRect();
        return bounds.top < window.innerHeight && bounds.bottom > 0;
      }
      return false;
    };

    const animateClass = () => {
      Object.keys(chapters).forEach((chapterName) => {
        if (isElementOnScreen(chapterName)) {
          setActiveChapter(chapterName);
        }
      });
    };

    window.addEventListener('scroll', animateClass);

    return () => {
      window.removeEventListener('scroll', animateClass);
    };
  }, [chapters, mapBoxStyle]);

  useEffect(() => {
    if (
      mapData.length === 0 &&
      chapters.length === 0 &&
      Object.values(mapDataArray).length > 0
    ) {
      initializeMapData();
    } else {
      initializeMap();
    }
  }, [
    mapData.length,
    chapters,
    initializeMap,
    initializeMapData,
    mapDataArray,
  ]);

  return (
    <>
      <div id="map" />
      <div id="features">
        {mapData.map((item, index) => (
          <section
            key={`storyTelling${index}`}
            id={index}
            className={`map-section-content mt--200 ${
              index === 0 ? 'active' : ''
            }`}
          >
            <h3>{item.heading}</h3>
            <TextBlockTwo content={item.textBlock} />
          </section>
        ))}
      </div>
    </>
  );
};

MapTwo.propTypes = {
  mapDataArray: PropTypes.array.isRequired,
};

export default memo(MapTwo);
