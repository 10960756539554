import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

import PropTypes from 'prop-types';
import { siteUrl, vimeoUrl } from '../utils/constants';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [about, setAbout] = useState({});
  const [author, setAuthor] = useState({});
  const [archiveCollectionName, setArchiveCollectionName] = useState(null);
  const [archiveCollectionItemId, setArchiveCollectionItemId] = useState(null);
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [cases, setCases] = useState([]);
  const [contact, setContact] = useState({});
  const [contributors, setContributors] = useState({});
  const [documents, setDocuments] = useState([]);
  const [home, setHome] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);
  const [sponsors, setSponsors] = useState({});
  const [tags, setTags] = useState([]);
  const [videos, setVideos] = useState([]);
  const [videosVimeo, setVideosVimeo] = useState([]);

  const setCollectionNameAndItemId = (collectionIdentifier, newId) => {
    setArchiveCollectionName(collectionIdentifier);
    setArchiveCollectionItemId(newId);
  };

  useEffect(() => {
    axios
      .get(
        `${siteUrl}/api/abouts?populate[teamMember][populate]=*&populate[methodology][populate]=*`
      )
      .then((response) => setAbout(response.data.data[0].attributes));

    axios
      .get(
        `${siteUrl}/api/authors?populate[timeline][populate]=*&populate[news][populate]=*&populate=*&populate[image][populate]=*&populate=*&populate[headingTextBlock][populate]=*&populate=*`
      )
      .then((response) => setAuthor(response.data.data[0].attributes));

    axios
      .get(`${siteUrl}/api/background-images?populate=*`)
      .then((response) => setBackgroundImages(response.data.data));

    axios
      .get(
        `${siteUrl}/api/cases?&populate[chapterOne][populate]=*&populate[chapterTwo][populate]=*&populate[chapterThree][populate]=*&populate[chapterFour][populate]=*&populate[chapterFive][populate]=*&populate[chapterSix][populate]=*&populate[chapterSeven][populate]=*&populate[chapterEight][populate]=*&populate[backgroundImage][populate]=*`
      )
      .then((response) => setCases(response.data.data));

    axios
      .get(
        `${siteUrl}/api/documents?populate[contentType][populate]=*&populate[file][populate]=*`
      )
      .then((response) => setDocuments(response.data.data));

    axios
      .get(`${siteUrl}/api/contacts?populate[formText][populate]=*`)
      .then((response) => setContact(response.data.data[0].attributes));

    axios
      .get(
        `${siteUrl}/api/contributors?populate[text][populate]=*&populate[contributor][populate]=*`
      )
      .then((response) =>
        setContributors(response?.data?.data?.[0]?.attributes)
      );

    axios
      .get(`${siteUrl}/api/sliders?populate=*`)
      .then((response) => setHome(response.data.data));

    axios.get(`${siteUrl}/api/sliders?populate=*`).then((result) => {
      setSocialMedia([]);
    });

    axios
      .get(`${siteUrl}/api/sponsors?populate=*`)
      .then((response) => setSponsors(response.data.data[0]));

    axios
      .get(`${siteUrl}/api/tags`)
      .then((response) => setTags(response.data.data));

    axios
      .get(
        `${siteUrl}/api/videos?populate[contentType][populate]=*&populate[file][populate]=*`
      )
      .then((response) => setVideos(response.data.data));

    axios
      .get(`${vimeoUrl}`, {
        headers: {
          Authorization: `Bearer f19f183f1e871b445ef287242e84c2b1`,
        },
      })
      .then((response) => {
        setVideosVimeo(response.data.data);
      });
  }, []);

  return (
    <DataContext.Provider
      value={{
        about,
        author,
        archiveCollectionName,
        archiveCollectionItemId,
        backgroundImages,
        cases,
        contact,
        contributors,
        documents,
        home,
        setArchiveCollectionName,
        socialMedia,
        sponsors,
        tags,
        setCollectionNameAndItemId,
        videos,
        videosVimeo,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
