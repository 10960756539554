export const accessToken =
  'pk.eyJ1IjoiY3VsdHVyYWxlY29sb2dpZXNvZm1lbW9yeSIsImEiOiJja3QzbmRuZDkwaWF1MnZwaWRyYzRuOWUzIn0.zPyzqlCTHIjQuQzBAakVYw';

export const siteUrl = window.location.href.includes('localhost')
  ? 'https://www.strapi.culturalecologies.com'
  : 'https://www.strapi.culturalecologies.com';

export const vimeoUrl =
  'https://api.vimeo.com/users/ricardovelasco/videos?per_page=99&fields=pictures,uri';

export const slickDot = {
  infinite: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 993,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const chapterNames = [
  'chapterOne',
  'chapterTwo',
  'chapterThree',
  'chapterFour',
  'chapterFive',
  'chapterSix',
  'chapterSeven',
  'chapterEight',
];

export const swiperDelay = 7000;
