/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useState } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import { isMobileOnly } from 'react-device-detect';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import PageHelmet from './common/Helmet';
import Header from './common/Header';
import Breadcrumb from './common/Breadcrumb';
import ChapterButtons from './common/ChapterButtons';
import Timeline from './objects/Timeline';
import TextBlock from './objects/TextBlock';
import Image from './objects/Image';
import BrandTwo from './BrandTwo';
import AboutThree from './about/AboutThree';
import FooterTwo from './common/FooterTwo';
import { siteUrl, slickDot } from '../utils/constants';
import { imgSize } from '../utils/funcs';

// Utility function to get image URL
const getImageUrl = (image, format = 'small') =>
  image?.data?.attributes?.formats?.[format]?.url
    ? siteUrl + image.data.attributes.formats[format].url
    : '';

// NewsLink Component
const NewsLink = ({ newsImage }) => (
  <Link
    to={{ pathname: newsImage.url }}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img
      className="w-100"
      src={getImageUrl(newsImage.image)}
      alt="Blog Images"
    />
  </Link>
);

NewsLink.propTypes = {
  newsImage: PropTypes.shape({
    url: PropTypes.string,
    image: PropTypes.object,
  }).isRequired,
};

// NewsLinkItem Component
const NewsLinkItem = ({ newsImage }) => (
  <>
    <div className="thumbnail">
      <NewsLink newsImage={newsImage} />
    </div>
    <div className="content">
      <div className="inner">
        <div className="content_heading">
          <h4 className="title">
            <Link to={{ pathname: newsImage.url }} target="_blank">
              {newsImage?.heading}
            </Link>
          </h4>
          <div className="category_list">
            <Link to={{ pathname: newsImage.url }} target="_blank">
              {newsImage?.text}
            </Link>
          </div>
        </div>
        <div className="content_footer">
          <Link
            to={{ pathname: newsImage.url }}
            target="_blank"
            className="rn-btn btn-opacity"
          >
            Read More
          </Link>
        </div>
      </div>
      <Link
        className="transparent_link"
        to={{ pathname: newsImage.url }}
        target="_blank"
      />
    </div>
  </>
);

NewsLinkItem.propTypes = {
  newsImage: PropTypes.shape({
    url: PropTypes.string,
    heading: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.object,
  }).isRequired,
};

// NewsSection Component
const NewsSection = ({ news }) => (
  <div className="rn-blog-area pt--120 pb--140 bg_color--5">
    <div className="container">
      <div className="row align-items-end">
        <div className="col-lg-12">
          <div className="section-title text-center">
            <span className="subtitle">News</span>
            <h3 className="title">Latest Updates</h3>
          </div>
        </div>
      </div>
      <div className="row mt_sm--30 rn-slick-dot slick-space-gutter--15 slickdot--20 row--0">
        <div className="col-lg-12">
          {news && (
            <Slider {...slickDot}>
              {news.map((newsImage, i) => (
                <div className="im_box" key={i}>
                  <NewsLinkItem newsImage={newsImage} />
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </div>
  </div>
);

NewsSection.propTypes = {
  news: PropTypes.array,
};

// BackgroundSection Component
const BackgroundSection = ({ backgroundImg, isMobile, heading, textBlock }) => (
  <div
    className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image"
    data-black-overlay="5"
    style={{
      backgroundImage: `url(${imgSize(
        backgroundImg?.attributes?.image?.data,
        isMobile
      )})`,
      backgroundAttachment: isMobile ? 'scroll' : 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="inner">
            <div className="content-wrapper">
              <div className="content">
                <h3 className="theme-gradient">{heading}</h3>
                <TextBlock textBlock={textBlock} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

BackgroundSection.propTypes = {
  backgroundImg: PropTypes.object,
  isMobile: PropTypes.bool,
  heading: PropTypes.string,
  textBlock: PropTypes.object,
};

// TimelineSection Component
const TimelineSection = ({ superHeading, heading, text, timeline }) => (
  <div className="rn-counterup-area ptb--120 bg_color--5">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-title text-center">
            <span className="subtitle">{superHeading}</span>
            <h3 className="title">{heading}</h3>
            <p className="description">{text}</p>
          </div>
        </div>
        <Timeline timeline={timeline || []} />
      </div>
    </div>
  </div>
);

TimelineSection.propTypes = {
  superHeading: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  timeline: PropTypes.array,
};

// Author Component
const Author = ({ author, backgroundImages, sponsors }) => {
  const [activeChapterIndex, setActiveChapterIndex] = useState(0);
  const backgroundSection = 'About';
  const section = 'Author';

  const backgroundImg = backgroundImages?.find(
    (item) => item.attributes?.section === backgroundSection.toLowerCase()
  );

  const setActiveChapter = (containerId) => {
    setActiveChapterIndex(containerId);
  };

  return (
    <>
      <PageHelmet pageTitle={section} />
      <Header section={section} />
      <Breadcrumb backgroundSection={backgroundSection} title={section} />
      <div className="about-area ptb--60 bg_color--1">
        <AboutThree
          heading={author?.headingOne}
          superHeading={author?.superHeadingOne}
          text={author?.textOne}
          imageUrl={getImageUrl(author?.image, 'medium')}
        />
      </div>
      <div id="bttns" />
      <ChapterButtons
        activeContainer={activeChapterIndex}
        activateContainer={setActiveChapter}
        caseName={author}
      />
      <div className="container mt-xl-5 mb-xl-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner-wrapper">
              <div className="inner">
                <BlocksRenderer
                  content={
                    author?.headingTextBlock?.[activeChapterIndex]?.textBlock ||
                    []
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsSection news={author?.news} />
      <BackgroundSection
        backgroundImg={backgroundImg}
        isMobile={isMobileOnly}
        heading={author?.headingThree}
        textBlock={author?.textThree}
      />
      <TimelineSection
        superHeading={author?.superHeadingTwo}
        heading={author?.headingTwo}
        text={author?.textTwo}
        timeline={author?.timeline}
      />
      {backgroundImages.length > 0 && (
        <Image
          caption={
            backgroundImg?.attributes?.image?.data?.attributes?.caption || ''
          }
          cssClass="mb-dec--40"
          imgType="fullWidth"
          imgUrl={imgSize(
            backgroundImg?.attributes?.image?.data?.attributes,
            isMobileOnly
          )}
        />
      )}
      <BrandTwo sponsors={sponsors} />
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      <FooterTwo />
    </>
  );
};

Author.propTypes = {
  author: PropTypes.object,
  backgroundImages: PropTypes.array,
  sponsors: PropTypes.object,
};

export default memo(Author);
