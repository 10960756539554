import React, { memo, useCallback } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';

import PageHelmet from './common/Helmet';
import Header from './common/Header';
import Breadcrumb from './common/Breadcrumb';
import Image from './objects/Image';
import BrandTwo from './BrandTwo';
import FooterTwo from './common/FooterTwo';
import TabThree from './tab/TabThree';

import { imgSize } from '../utils/funcs';

const Archive = ({ backgroundImages, documents, sponsors, videos }) => {
  const section = 'Archive';

  const findBackgroundImage = useCallback(
    () =>
      backgroundImages.find(
        (item) =>
          item.attributes.section?.toLowerCase() === section.toLowerCase()
      ) ?? null,
    [backgroundImages, section]
  );

  const renderTabThree = useCallback(
    () => (
      <div className="designer-portfolio-area ptb--120 bg_color--1">
        <div className="wrapper plr--70 plr_sm--30 plr_md--30">
          <TabThree
            column="col-lg-4 col-md-6 col-sm-6 col-12 "
            documents={documents}
            videos={videos}
          />
        </div>
      </div>
    ),
    [documents, videos]
  );

  const renderBackgroundImage = useCallback(() => {
    const backgroundImage = findBackgroundImage();
    const imgAttributes = backgroundImage?.attributes?.image?.data?.attributes;

    if (!imgAttributes || backgroundImages.length === 0) return null;

    return (
      <Image
        caption={imgAttributes.caption ?? ''}
        cssClass="mb-dec--40"
        imgType="fullWidth"
        imgUrl={imgSize(imgAttributes, isMobileOnly)}
      />
    );
  }, [backgroundImages, findBackgroundImage]);

  return (
    <>
      <PageHelmet pageTitle="About" />
      <Header section={section} />

      <Breadcrumb backgroundSection={section} title="Archive" />

      {renderTabThree()}
      {renderBackgroundImage()}

      <BrandTwo sponsors={sponsors} />

      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>

      <FooterTwo />
    </>
  );
};

Archive.propTypes = {
  backgroundImages: PropTypes.array,
  documents: PropTypes.array,
  sponsors: PropTypes.object,
  videos: PropTypes.array,
};

export default memo(Archive);
